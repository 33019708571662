@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap');
html,body {
    font-family: 'Raleway', sans-serif;  
}
.thankyou-page ._header {
    background: #fff;
    padding: 80px 30px;
    text-align: center;
    background: #fff center/cover no-repeat;
}
.thankyou-page ._header .logo {
    max-width: 250px;
    margin: 0 auto 10px;
}
.thankyou-page ._header .logo img {
    width: 100%;
}
.thankyou-page ._header h1 {
    font-size: 65px;
    font-weight: 800;
    color: #0f89da;
    margin: 0;
}
.thankyou-page ._body {
    margin: -70px 0 15px;
}
.thankyou-page ._body ._box {
    margin: auto;
    max-width: 80%;
    padding: 50px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
    -moz-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
    -webkit-box-shadow: 0 0 35px rgba(10, 10, 10,0.12);
}
.thankyou-page ._body ._box h2 {
    font-size: 25px;
    font-weight: 300;
    color: #4ab74a;
}
.thankyou-page ._footer {
    text-align: center;
    padding: 50px 30px;
}

.thankyou-page ._footer .btn {
    background: #0f89da;
    color: white;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    letter-spacing: 0.8px;
    padding: 20px 33px;
    text-transform: uppercase;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 380px) and (max-width: 767) {

    .thankyou-page {
        margin-top: 35%;
    }


    .thankyou-page ._header .logo {
        max-width: 459px;
        margin: 0 auto 10px;
    }

    .thankyou-page ._footer .btn {

        font-size: 35px;

    }

    h2 {
        text-align: center;
        font-weight: 300;
        font-size: 28px;
    }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
    .thankyou-page {
        margin-top: 20%;
    }


    .thankyou-page ._header .logo {
        max-width: 459px;
        margin: 0 auto 10px;
    }

    .thankyou-page ._footer .btn {

        font-size: 35px;

    }

    h2 {
        text-align: center;
        font-weight: 300;
        font-size: 28px;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}