// // Price
// .price-border {
//   transform: rotateX(180deg);
//   margin-bottom: -10px;
// }
// .price-border-reverse {
//   margin-top: -10px;
//   transform: scale(-1, 1);
// }
// .price-section {
//   background: #0277bd;
//   padding: 10px 10px 50px;
//   position: relative;
// }
// .price-container {
//   border-radius: 50px;
//   h2 {
//     color: #fff;
//   }
//   .pricing-subtitle {
//     background: #ffc107;
//     border-radius: 10px;
//     max-width: 710px;
//     margin: 20px auto;
//   }
// }
// .price-card {
//   border-radius: 15px;
//   .discount-text {
//     font-size: 16px;
//     margin-bottom: 10px;
//   }
// }
// .price {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
// }
// .price-value {
//   display: flex;
// }
// .select-plan-button {
//   margin-top: 10px !important;
// }
// .feature-text {
//   margin: 0 auto !important;
//   background: white;
//   padding: 5px;
//   border-radius: 10px;
//   border: 2px solid;
//   max-width: 950px;
//   display: block !important;
// }
// .sample-button {
//   max-width: 230px;
//   margin: 15px auto !important;
//   display: block !important;
//   text-align: center;
// }
// // EndPrice

.pricing-table {
  background-color: #eee;
  font-family: "Montserrat", sans-serif;
}

.pricing-table .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.pricing-table .block-heading h2 {
  color: #3b99e0;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.pricing-table .heading {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-table .item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  border-top: 2px solid #5ea4f3;
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px;
}

.pricing-table .item button {
  font-weight: 600;
}

.pricing-table .ribbon-checkout {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
}

.pricing-table .features .feature {
  font-weight: 600;
}
.features ul {
  list-style: none;
  padding: 0;
}

.value {
  font-size: 18px;
  padding: 5px;
  // line-height: 2.5rem;
  margin-bottom: 0.5rem;
}
.price-footer {
  position: absolute;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  // right: 50%;
  right: 0;
  left: 0;
}
.pricing-table .price h4 {
  margin: 15px 0;
  font-size: 45px;
  text-align: center;
  color: #2288f9;
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0;
}
